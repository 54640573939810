import jQuery from 'jquery';
import 'bootstrap';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import whereToBuy from './routes/whereToBuy';

import WebFont from 'webfontloader';

import 'owl.carousel';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core'




dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Droid Sans', 'Source Sans Pro', 'Montserrat:500,700,900']
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** About Us page, note the change from about-us to aboutUs. */
    whereToBuy,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());