import jQuery from 'jquery';
// map update 8   
export default {  
    init() {
        console.log('map update 8');  
    },
    finalize() {

        (function($) {
            'use strict';

            // global variables
            let map = null;
            let data_layer;
            let geocoder;
            let distributors = [];
            let salesReps = [];
            // let outsideSalesReps = [];

            let north = ['Montana', 'Wyoming', 'North Dakota', 'South Dakota', 'Minnesota', 'Iowa'];
            let northRegion  = [];
            let northDataLayer;

            let south = ['Colorado', 'Nebraska', 'Kansas', 'Oklahoma', 'Texas', 'Missouri', 'Arkansas', 'Louisiana'];
            let southRegion = [];
            let southDataLayer;

            let east = ['Wisconsin', 'Illinois', 'Kentucky', 'Tennessee', 'Mississippi', 'Michigan', 'Indiana', 'Alabama', 'Ohio', 'Georgia', 'Florida', 'West Virginia', 'Virginia', 'North Carolina', 'South Carolina', 'New York', 'Pennsylvania', 'Maryland', 'New Jersey', 'Maine', 'Vermont', 'New Hampshire', 'Delaware', 'Rhode Island', 'Connecticut', 'Massachusetts'];
            let eastRegion = [];
            let eastDataLayer;

            let west = ['Washington', 'Oregon', 'Idaho', 'California', 'Nevada', 'Utah', 'Arizona', 'New Mexico'];
            let westRegion = [];
            let westDataLayer;
			
			let exclude = [];

            $(document).ready(function() {
                getDistributors();
                getSalesRep();
                // getOutsideSalesRep();
                let myLatlng = new google.maps.LatLng(38, -97);
                let myOptions = {
                    zoom: 5,
                    center: myLatlng,
                    mapTypeControl: false,
                    // mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                    navigationControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    streetViewControl: false,
                };
                map = new google.maps.Map(document.getElementById("google-map"), myOptions);
                geocoder = new google.maps.Geocoder();
                loadBoundariesFromGeoJson("https://raw.githubusercontent.com/matej-pavla/Google-Maps-Examples/master/BoundariesExample/geojsons/us.states.geo.json");
                $('.distributor-row').on('click', '.map-modal-btn', toggleMapModal);
                $('#map-modal').on('click', '.modal-close-btn', closeBioModal);
            });

            // initializes data layers on the map
            function initializeDataLayer() {
                if (data_layer) {
                    data_layer.forEach(function(feature) {
                        data_layer.remove(feature);
                    });
                    data_layer = null;
                }

                northDataLayer = new google.maps.Data({map: map});
                northDataLayer.setStyle({
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    strokeOpacity: .75,
                    fillColor: '#F1C665',
                    fillOpacity: .5
                });
                addEventListeners(northDataLayer);

                southDataLayer = new google.maps.Data({map: map});
                southDataLayer.setStyle({
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    strokeOpacity: .75,
                    fillColor: '#704f20',
                    fillOpacity: .5
                });
                addEventListeners(southDataLayer);

                eastDataLayer = new google.maps.Data({map: map});
                eastDataLayer.setStyle({
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    strokeOpacity: .75,
                    fillColor: '#738e46',
                    fillOpacity: .5
                });
                addEventListeners(eastDataLayer);

                westDataLayer = new google.maps.Data({map: map});
                westDataLayer.setStyle({
                    strokeWeight: 1,
                    strokeColor: '#FFFFFF',
                    strokeOpacity: .75,
                    fillColor: '#0f4c2a',
                    fillOpacity: .5
                });
                addEventListeners(westDataLayer);
            }

            // adds event listeners to map
            function addEventListeners(data_layer) {
                let regionFillColor = '';
                if (data_layer == northDataLayer) {
                    regionFillColor = '#F1C665';
                }
                if (data_layer == southDataLayer) {
                    regionFillColor = '#704f20';
                }
                if (data_layer == eastDataLayer) {
                    regionFillColor = '#738e46';
                }
                if (data_layer == westDataLayer) {
                    regionFillColor = '#0f4c2a';
                }

                data_layer.addListener('click', function(e) { //we can listen for a boundary click and identify boundary based on e.feature.getProperty('boundary_id'); we set when adding boundary to data layer
                    $('#no-distributors-found').empty();
                    $('#sales-rep').empty();
                    $('#ag-distributor-grid').empty();
                    $('#turf-distributor-grid').empty();
                    $('#organics-distributor-grid').empty();
                    $('#ag-distributor-title').empty();
                    $('#turf-distributor-title').empty();
                    $('#organics-distributor-title').empty();
                    $('#direct-enterprises').empty();
                    $('#unified_ag_solutions').empty();

                    let stateDist = [];
                    let agricultureDistributors = [];
                    let turfDistributors = [];
                    let organicsDistributors = [];
                    let marker = '';
                    let activeStateID = e.feature.getProperty('boundary_id');
                    //console.log('activeStateID', activeStateID);

                    for (let distributor of distributors) {
                        if (distributor.stateID.includes(activeStateID)) {
                            stateDist.push(distributor);
                            // console.log('stateDist', stateDist);
                            distributor.category.forEach( function(category) {
                                if (category.name == 'Agriculture') {
                                    agricultureDistributors.push(distributor);
                                    // console.log('agriculture', agricultureDistributors);
                                }
                                if (category.name == 'Turf') {
                                    turfDistributors.push(distributor);
                                    // console.log('turf', turfDistributors);
                                }
                                if (category.name == 'Organics') {
                                    organicsDistributors.push(distributor);
                                    // console.log('organics', organicsDistributors);
                                }
                            })
                        }
                    }

                    if (agricultureDistributors.length !== 0) {
                        $('#ag-distributor-title').append('<h3 class="distributor-heading pt-5 pb-3">Agriculture Distributors</h3>');
                        for (let distributor of agricultureDistributors) {
                            let distributorCard = $(`
                                <div class="col-lg-3">
                                    <div class="distributor-card p-3">
                                        <h3 class="distributor-card-title">${distributor.title}</h3>
                                        <hr>
                                        <div>
                                            <p class="distributor-card-link">
                                                ${(distributor.name ?
                                                    `Contact: ${distributor.name}` : ``)}
                                            </p>
                                            <p class="distributor-card-link">
                                                <i class="fas fa-map-marker-alt mr-3"></i>${(distributor.address ? `${distributor.address}` : `No Address Provided.`)}
                                            </p>
                                            <p>
                                                ${(distributor.phone ?
                                                    `<a class="distributor-card-link" href="tel:1-${distributor.phone}">
                                                        <i class="fas fa-phone mr-3"></i>${distributor.phone}
                                                    </a>`
                                                :
                                                `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.email ?
                                                    `<a class="distributor-card-link" href="mailto:${distributor.email}"><i class="fas fa-paper-plane mr-3"></i>${distributor.email}</a><br>`
                                                    :
                                                    `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.website ?
                                                    `<a class="distributor-card-link" href="${distributor.website}" target="_blank" rel="noopener"><i class="fas fa-link mr-3"></i>Visit Website</a><br>`
                                                    :
                                                    `<span></span>`)}
                                            </p>
                                            ${(distributor.gmap ? 
                                            `<button class="map-modal-btn" data-id="${distributor.title}">View Map</button>`
                                            : ``)}
                                        </div>
                                    </div>
                                </div>`);
                            $('#ag-distributor-grid').append(distributorCard);
                        }
                    }

                    if (turfDistributors.length !== 0) {
                        $('#turf-distributor-title').append('<h3 class="distributor-heading pt-5 pb-3">Turf Distributors</h3>');
                        for (let distributor of turfDistributors) {
                            let distributorCard = $(`
                                <div class="col-lg-3">
                                    <div class="distributor-card p-3">
                                        <h3 class="distributor-card-title">${distributor.title}</h3>
                                        <hr>
                                        <div>
                                            <p class="distributor-card-link">
                                                <i class="fas fa-map-marker-alt mr-3"></i>${(distributor.address ? `${distributor.address}` : `No Address Provided.`)}
                                            </p>
                                            <p>
                                                ${(distributor.address ?
                                `<a class="distributor-card-link" href="tel:1-${distributor.phone}">
                                                        <i class="fas fa-phone mr-3"></i>${distributor.phone}
                                                    </a>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.email ?
                                `<a class="distributor-card-link" href="mailto:${distributor.email}"><i class="fas fa-paper-plane mr-3"></i>${distributor.email}</a><br>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.website ?
                                `<a class="distributor-card-link" href="${distributor.website}" target="_blank" rel="noopener"><i class="fas fa-link mr-3"></i>Visit Website</a><br>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <button class="map-modal-btn" data-id="${distributor.title}">View Map</button>
                                        </div>
                                    </div>
                                </div>`);
                            $('#turf-distributor-grid').append(distributorCard);
                        }
                    }

                    if (organicsDistributors.length !== 0) {
                        $('#organics-distributor-title').append('<h3 class="distributor-heading pt-5 pb-3">Organics Distributors</h3>');
                        for (let distributor of organicsDistributors) {
                            let distributorCard = $(`
                                <div class="col-lg-3">
                                    <div class="distributor-card p-3">
                                        <h3 class="distributor-card-title">${distributor.title}</h3>
                                        <hr>
                                        <div>
                                            <p class="distributor-card-link">
                                                <i class="fas fa-map-marker-alt mr-3"></i>${(distributor.address ? `${distributor.address}` : `No Address Provided.`)}
                                            </p>
                                            <p>
                                                ${(distributor.address ?
                                `<a class="distributor-card-link" href="tel:1-${distributor.phone}">
                                                        <i class="fas fa-phone mr-3"></i>${distributor.phone}
                                                    </a>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.email ?
                                `<a class="distributor-card-link" href="mailto:${distributor.email}"><i class="fas fa-paper-plane mr-3"></i>${distributor.email}</a><br>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <p>
                                                ${(distributor.website ?
                                `<a class="distributor-card-link" href="${distributor.website}" target="_blank" rel="noopener"><i class="fas fa-link mr-3"></i>Visit Website</a><br>`
                                :
                                `<span></span>`)}
                                            </p>
                                            <button class="map-modal-btn" data-id="${distributor.title}">View Map</button>
                                        </div>
                                    </div>
                                </div>`);
                            $('#organics-distributor-grid').append(distributorCard);
                        }
                    }

                    // handles what sales rep is viewed based on the region of the state you have clicked
                    for (let salesrep of salesReps) {

                        let salesrepCard = $(`
                                <div class="salesrep-card">
                                    <div class="pt-3 salesrep-card-heading text-center">
                                        <h6>${activeStateID} Account Manager</h6>
                                    </div>
                                    <hr>
                                    <div class="row m-auto px-0">
                                        <div class="col-md-12 p-0 d-flex justify-content-center flex-wrap">
                                            <div class="card-img-container mb-3">
                                                <img class="salesrep-card-img" src="${salesrep.rep_profile_pic}">
                                            </div>
                                            <div class="pl-2">
                                                <div class="salesrep-card-name">${salesrep.rep_name}</div>
                                                <div class="salesrep-card-title">${salesrep.rep_title}</div>
                                                <div class="salesrep-card-email">
                                                    <a class="" href="mailto:${salesrep.rep_email}">
                                                        ${salesrep.rep_email}
                                                    </a>
                                                </div>
                                                <div class="salesrep-card-phone">
                                                    <a class=""  href="tel:1-${salesrep.rep_phone}">
                                                        ${salesrep.rep_phone}
                                                    </a>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            `);

                        if (west.includes(activeStateID)) {
                            if (salesrep.rep_region == 'west') {
                                if (salesrep.rep_exclusion == 'on') {
									if (salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').append(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state match!');
									} else if (!salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').hide(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state DO NOT match!');
									}
									console.log(salesrep.rep_name, ': has exclusion');
								} else {
									$('#sales-rep').append(salesrepCard);
									console.log(salesrep.rep_name, ': does not have exclusion');
								}
                            }
                        }
                        if (north.includes(activeStateID)) {
                            if (salesrep.rep_region == 'north') {
								if (salesrep.rep_exclusion == 'on') {
									if (salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').append(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state match!');
									} else if (!salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').hide(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state DO NOT match!');
									}
									console.log(salesrep.rep_name, ': has exclusion');
								} else {
									$('#sales-rep').append(salesrepCard);
									console.log(salesrep.rep_name, ': does not have exclusion');
								}
                            }
                        }
                        if (south.includes(activeStateID)) {
                            if (salesrep.rep_region == 'south') {
                                if (salesrep.rep_exclusion == 'on') {
									if (salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').append(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state match!');
									} else if (!salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').hide(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state DO NOT match!');
									}
									console.log(salesrep.rep_name, ': has exclusion');
								} else {
									$('#sales-rep').append(salesrepCard);
									console.log(salesrep.rep_name, ': does not have exclusion');
								}
                            }
                        }
                        if (east.includes(activeStateID)) {
                            if (salesrep.rep_region == 'east') {
                                if (salesrep.rep_exclusion == 'on') {
									if (salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').append(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state match!');
									} else if (!salesrep.rep_states.includes(activeStateID)) {
										$('#sales-rep').hide(salesrepCard);
										console.log(salesrep.rep_name, ': rep state & active state DO NOT match!');
									}
									console.log(salesrep.rep_name, ': has exclusion');
								} else {
									$('#sales-rep').append(salesrepCard);
									console.log(salesrep.rep_name, ': does not have exclusion');
								}
                            }
                        }
                    }


                    // $('#outside-sales-rep').css('display', 'block');

                    // for (let outsideSalesRep of outsideSalesReps) {
                        // let directEnterprisesCard;
                        // let unifiedAgSolutions;
                        // console.log(activeStateID);
                        // for (let stateID of outsideSalesRep.os_rep_region) {
                            // if (stateID.state == activeStateID) {
                                // if (outsideSalesRep.os_rep_company_select == 'direct_enterprises_inc') {
                                //     directEnterprisesCard = $(`
                                //         <div class="outside-salesrep-card">
                                //             <div class="row m-auto px-0">
                                //                 <div class="col-md-12 p-0">
                                //                     <div class="pl-2">
                                //                         <div class="salesrep-card-name">${outsideSalesRep.os_rep_name}</div>
                                //                         <div class="salesrep-card-phone">
                                //                             <a class=""  href="tel:1-${outsideSalesRep.os_rep_phone}">
                                //                                 ${outsideSalesRep.os_rep_phone}
                                //                             </a>
                                //                         </div>
                                //                         </div>
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     `);
                                // }

                                // if (outsideSalesRep.os_rep_company_select == 'unified_ag_solutions') {
                                //     unifiedAgSolutions = $(`
                                //         <div class="outside-salesrep-card">
                                //             <div class="row m-auto px-0">
                                //                 <div class="col-md-12 p-0">
                                //                     <div class="pl-2">
                                //                         <div class="salesrep-card-name">${outsideSalesRep.os_rep_name}</div>
                                //                         <div class="salesrep-card-phone">
                                //                             <a class=""  href="tel:1-${outsideSalesRep.os_rep_phone}">
                                //                                 ${outsideSalesRep.os_rep_phone}
                                //                             </a>
                                //                         </div>
                                //                         </div>
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     `);
                                // }

                                // if (directEnterprisesCard) {
                                //     $('#direct-enterprises').append(directEnterprisesCard);
                                // }
                                //
                                // if (unifiedAgSolutions) {
                                //     $('#unified_ag_solutions').append(unifiedAgSolutions);
                                // }
                            // }
                        // }

                        // if (directEnterprisesCard) {
                        //     $('#direct-enterprises').append(directEnterprisesCard);
                        // }

                        // if (unifiedAgSolutions) {
                        //     $('#unified_ag_solutions').append(unifiedAgSolutions);
                        // }
                    // }

                    // appends 'No Distributors Found' for a state that doesn't have any distributors entered in the database
                    if (stateDist.length === 0) {
                        // console.log('checking if distributors are present');
                        let message = $(`
                                    <h5 class="no-distributors-found py-5">
                                        No distributors found in ${activeStateID}.
                                    </h5>
                                `);
                        $('#no-distributors-found').append(message);
                    }

                    let clickCoordinates = e.latLng;
                    map.setZoom(6);
                    map.panTo(clickCoordinates);

                    if (geocoder) {

                        for (let location of stateDist) {
                            geocoder.geocode({'address': location.address}, function (results, status) {
                                if (status == google.maps.GeocoderStatus.OK) {
                                    if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {

                                        if (location.address) {
                                            // creates map markers for the state that has been clicked on
                                            marker = new google.maps.Marker({
                                                position: results[0].geometry.location,
                                                map: map,
                                                title: location.address
                                            });

                                            // markup for info window
                                            let contentString = `
                                                <div>
                                                    <p>${location.title}</p>
                                                    <p><a href="tel:1-952-657-5592">${location.phone}</a></p>
                                                    <p><a href="${location.website}" target="_blank">Visit Website</a></p>
                                                </div>
                                             `;

                                            // creates infowindow for map markers
                                            let infowindow = new google.maps.InfoWindow({
                                                content: contentString
                                            });

                                            // adds click listener for map marker
                                            marker.addListener('click', function () {
                                                infowindow.open(map, this);
                                            });
                                        }
                                    }
                                    else {
                                        console.log('No results found');
                                    }
                                }
                                else {
                                    console.log('Google Maps was not successful for the following reason: ' + status);
                                }
                            });
                        }
                    }
                });

                // changes the color of the state you are hovering
                data_layer.addListener('mouseover', function(e) {
                    let activeStateID = e.feature.getProperty('boundary_id').toString();
                    data_layer.overrideStyle(e.feature, {
                        strokeWeight: 1,
                        strokeColor: '#FFFFFF',
                        strokeOpacity: .75,
                        fillColor: '#bfd84c',
                        fillOpacity: 0.5
                    });
                    $('#state-being-hovered').html(activeStateID);
                });

                // changes the color of the state back to the default color when you are done hovering
                data_layer.addListener('mouseout', function(e) {
                    data_layer.overrideStyle(e.feature, {
                        strokeWeight: 1,
                        strokeColor: '#FFFFFF',
                        strokeOpacity: .75,
                        fillOpacity: 0.5,
                        fillColor: regionFillColor,
                    });
                    $('#state-being-hovered').html('');
                });
            }

            // loads boundaries for each state on the map
            function loadBoundariesFromGeoJson(geo_json_url) {

                initializeDataLayer();
                $.getJSON(geo_json_url, function (data) {

                    let allStates = data.features;

                    if (data.type == 'FeatureCollection') { //we have a collection of boundaries in geojson format
                        if (allStates) {

                            for (let state of allStates) {
                                let boundary_id = state.properties.NAME;

                                let new_boundary = {};

                                let north_boundary = {};
                                let south_boundary = {};
                                let east_boundary = {};
                                let west_boundary = {};

                                if (!state.properties) {
                                    state.properties = {};
                                }

                                state.properties.boundary_id = boundary_id; //we will use this id to identify boundary later when clicking on it

                                if (north.includes(boundary_id)) {
                                    northRegion.push(state);
                                    state.properties.boundary_id = boundary_id; //we will use this id to identify boundary later when clicking on it
                                    northDataLayer.addGeoJson(state, {idPropertyName: 'boundary_id'});
                                    north_boundary.feature = northDataLayer.getFeatureById(boundary_id);
                                }

                                if (south.includes(boundary_id)) {
                                    southRegion.push(state);
                                    state.properties.boundary_id = boundary_id; //we will use this id to identify boundary later when clicking on it
                                    southDataLayer.addGeoJson(state, {idPropertyName: 'boundary_id'});
                                    south_boundary.feature = southDataLayer.getFeatureById(boundary_id);
                                }

                                if (east.includes(boundary_id)) {
                                    eastRegion.push(state);
                                    state.properties.boundary_id = boundary_id; //we will use this id to identify boundary later when clicking on it
                                    eastDataLayer.addGeoJson(state, {idPropertyName: 'boundary_id'});
                                    east_boundary.feature = eastDataLayer.getFeatureById(boundary_id);
                                }

                                if (west.includes(boundary_id)) {
                                    westRegion.push(state);
                                    state.properties.boundary_id = boundary_id; //we will use this id to identify boundary later when clicking on it
                                    westDataLayer.addGeoJson(state, {idPropertyName: 'boundary_id'});
                                    west_boundary.feature = westDataLayer.getFeatureById(boundary_id);
                                }
                            }
                        }
                    }
                });
            }

            // gets all distributors
            function getDistributors() {
                $.ajax({
                    method: 'GET',
                    url: '/wp-json/cpl/v1/distributor',
                })
                .then(function (response) {
                    distributors = response;
                })
                .catch(function (error) {
                    console.log('Error with getting distributors.', error);
                });
            }

            // gets all sales reps
            function getSalesRep() {
                $.ajax({
                    method: 'GET',
                    url: '/wp-json/cpl/v1/salesrep',
                })
                .then(function (response) {
                    salesReps = response;
                })
                .catch(function (error) {
                    console.log('Error with getting sales rep', error);
                });
            }

            // gets all outside sales reps
            // function getOutsideSalesRep() {
            //     $.ajax({
            //         method: 'GET',
            //         url: '/wp-json/cpl/v1/outside_salesrep',
            //     })
            //       .then(function (response) {
            //           outsideSalesReps = response;
            //           console.log(outsideSalesReps);
            //       })
            //       .catch(function (error) {
            //           console.log('Error with getting outside sales rep', error);
            //       });
            // }

            function toggleMapModal() {
                let name = $(this).attr("data-id");

                let mapModal = '';
                $('#map-modal').empty();

                for (let distributor of distributors) {
                    let title = distributor.title;
                    title = title.replace('#038;', '');

                    if (name == title) {
                        mapModal = $(`
                            <div class="modal-bg">
                                <div class="container">
                                    <div class="custom-modal-body row m-auto px-0">
                                        <div class="modal-heading">
                                            <span>${distributor.title}</span>
                                            <i class="fas fa-times modal-close-btn"></i>
                                        </div>
                                        <iframe src="${distributor.gmap}" width="100%" height="450px" frameborder="0" style="border:0" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        `);
                        $('#map-modal').append(mapModal);
                    }
                }
            }

            function closeBioModal() {
                $('#map-modal').empty();
            }

        })( jQuery );

    },
};