export default {
    init() {
       // console.log('common');
    },
    finalize() {

        (function($) {
            'use strict';

            $(document).ready(function() {
                eventListener();
                addProductName();
                disabledLinks();

                // nav menu v1
                // $('#wp-nav-menu-primary-menu li').hover(
                //     function(){
                //         //i used the parent ul to show submenu
                //         $(this).children('ul').slideDown();
                //     },
                //     //when the cursor away
                //     function () {
                //         $('ul', this).slideUp();
                //     }
                // );

                // this feature is only displayed on 600px device width
                $(".hamburger-menu").on('click', function() {
                    $(".burger-1, .burger-2, .burger-3").toggleClass("open");
                    $(".core-menu").slideToggle("fast");
                });

                $('.owl-carousel.featured-products-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        `<span class="blog-prev-button fa-stack">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="text-white fas fa-chevron-left fa-stack-1x"></i>
                        </span>`,
                        `<span class="blog-next-button fa-stack">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="text-white fas fa-chevron-right fa-stack-1x"></i>
                        </span>`
                    ],
                    items: 1,
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplaySpeed: 1000
                });

                $('.owl-carousel.posts-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<i class="blog-prev-button fas fa-chevron-left"></i>',
                        '<i class="blog-next-button fas fa-chevron-right"></i>'
                    ],
                    items: 3,
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplaySpeed: 1000,
                    responsive : {
                        // breakpoint from 0 up
                        0 : {
                            items: 1,
                        },
                        // breakpoint from 768 up
                        768 : {
                            items: 1,
                        },
                        // breakpoint from 922 up
                        922 : {
                            items: 3,
                        }
                    }
                });

            });

            function eventListener() {
                $('.show-hidden-menu').on('click', showTeamMemberBio);
            }

            // toogles view of team member's bio
            function showTeamMemberBio() {
                console.log('in show team member bio');
                $(this).next('.hidden-menu').slideToggle("slow");
            }

            // adds product name to form
            function addProductName() {
                let product = $('#product-name').text();
                //console.log(product);
                $('.current-product').find('input').val(product);
            }

            function disabledLinks() {
                $('.top-level-menu-item.menu-item-has-children').find('a').ref = '';
            }

            // function getUsersLocation() {
            //     console.log('in geotracker');
            //     let visitorLocation = $('#visitorLocation').text();
            //     let sentFrom = $('.sent-from');
            //     $('.sent-from:first input').val(visitorLocation.trim());
            // }

        })( jQuery );

    },
};